import React, { Component } from "react";
import { Carousel } from "antd";
import { withTranslation, Trans } from "react-i18next";
import { benefitSpecificCases, left, right } from "../../../images";
import CardOrganization from "../organization/CardOrganization";

class Reasons extends Component {

  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  
  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }
  
  render() {
    const { t } = this.props;

    const cardsReasons = t("Benefits.specificCases.benefits", {
      returnObjects: true
    }).map((reason, index) => (
      <CardOrganization
        key={index}
        iconName={reason.iconName}
        icon={benefitSpecificCases[reason.iconName]}
        description={<p style={{ fontSize: 12 }}><Trans>{reason.description}</Trans></p>}
      />
    ));

    return (
      <div className="container-section-benefit container-benefit-column">
      <h2 className="title-benefit">
          {t("Benefits.specificCases.title", { framework: "react-i18next" })}
        </h2>
        <p className="description-benefit">
          {t("Benefits.specificCases.description", {
            framework: "react-i18next"
          })}
        </p>
        <div className='container-cards-organization'>
          {cardsReasons}
        </div>
        <div className='container-carousel'>
          <img
            className="arrowRight"
            src={right}
            alt="right"
            onClick={this.next} />
          <Carousel
            ref={node => (this.carousel = node)}
            arrows={true}
          >
            {cardsReasons}
          </Carousel>
          <img
            className="arrowLeft"
            src={left}
            alt="left"
            onClick={this.previous} />
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(Reasons);