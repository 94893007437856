import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button } from "antd";
import {error404} from "../../images/index"

class Page404 extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container-error">
        <img
          width="316"
          height="120"
          className="image-404"
          alt="404"
          src={error404}
        />
        <p className="error-title">
          {t(`page404.title`, { framework: "react-i18next" })}
        </p>
        <p className="error-subtitle">
          {t(`page404.subtitle`, { framework: "react-i18next" })}
        </p>
        <Button
          className="button-error"
          onClick={() => this.props.history.push("/")}
        >
          {t("page404.backButton", { framework: "react-i18next" })}
        </Button>
      </div>
    );
  }
}

export default withTranslation("translations")(withRouter(Page404));
