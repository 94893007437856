import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import scrollToComponent from "react-scroll-to-component";
import Mytrust from "../components/mytrust/MyTrust";
import Navbar from "../components/mytrust/Navbar";
import Contact from "../components/mytrust/contact";
import Benefits from "../components/benefits/Benefits";
import BusinessCases from "../components/businessCases/BusinessCases";
import Customers from "../components/customers/Customers";
import Resources from "../components/resources/Resources";
import Footer from "../components/footer/Footer";
import Download from "../components/wallet/Wallet";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getObjectKey } from "../components/utils";

class LandingPage extends Component {
  state = {
    isMobile: false,
    headerTop: true
  };

  scrollToSection = component => {
    scrollToComponent(this[component], {
      offset: -35,
      align: "center",
      duration: 500
    });
  };

  componentDidMount() {
    if (this.props.langOfRoute === "es") {
      this.props.i18n.changeLanguage("es");
      !!this.props.routeSectionName && this.scrollWithSectionRouteName();
    } else {
      !!this.props.routeSectionName && this.scrollWithSectionRouteName();
    }

    this.props.routeSectionName.match("contact") &&
      this.props.showFormContact();

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.isScrollBottom);

    this.setState({ isMobile: this.isMobile() });
  }

  scrollWithSectionRouteName = () => {
    const componentNameToScroll = getObjectKey(
      this.props.t("sectionRoutes", { returnObjects: true }),
      this.props.routeSectionName
    );

    this.scrollToSection(componentNameToScroll);
  };

  isMobile = () => {
    return !!(
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    );
  };

  handleScroll = () => {
    if (window.scrollY === 0) {
      this.setState({ headerTop: true });
    } else if (window.scrollY !== 0) {
      this.setState({ headerTop: false });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <Helmet>
          <title>
            {t("head.title", {
              framework: "react-i18next"
            })}
          </title>
          <meta
            name="description"
            content={t("head.description", { framework: "react-i18next" })}
          />
          <meta
            property="og:title"
            content={t("head.titleOpenGraph", { framework: "react-i18next" })}
          />
          <meta
            property="og:description"
            content={t("head.descriptionOpenGraph", {
              framework: "react-i18next"
            })}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.kaytrust.id/" />
          <meta
            property="og:image"
            content="https://user-images.githubusercontent.com/32302890/63475168-694bbb80-c441-11e9-9184-32030307484c.png"
          />
          <meta name="twitter:card" content="summary_large_image" />

          <meta
            name="twitter:description"
            content={t("head.twitterDescription", {
              framework: "react-i18next"
            })}
          />
          <meta
            name="twitter:title"
            content={t("head.twitterTitle", { framework: "react-i18next" })}
          />
          <meta
            name="twitter:image"
            content="https://user-images.githubusercontent.com/32302890/63475168-694bbb80-c441-11e9-9184-32030307484c.png"
          />
          <meta name="twitter:creator" content="@KayTrustid" />
          <meta name="twitter:site" content="@KayTrustid" />
        </Helmet>
        <Navbar
          langOfRoute={this.props.langOfRoute}
          scrollToSection={this.scrollToSection}
          isMobile={this.state.isMobile}
          showFormContact={this.props.showFormContact}
          handleScroll={this.handleScroll}
          headerTop={this.state.headerTop}
        />
        <section id="Mytrust" ref={section => (this.Mytrust = section)}>
          <Mytrust
            isMobile={this.state.isMobile}
            scrollToSection={this.scrollToSection}
            showFormContact={this.props.showFormContact}
          />
        </section>
        <section id="Customers" ref={section => (this.Customers = section)}>
          <Customers showFormContact={this.props.showFormContact} />
        </section>
        <section id="Benefits" ref={section => (this.Benefits = section)}>
          <Benefits
            isMobile={this.state.isMobile}
            scrollToSection={this.scrollToSection}
            routeSectionName={this.props.routeSectionName}
            langOfRoute={this.props.langOfRoute}
          />
        </section>
        <section
          id="BusinessCases"
          ref={section => (this.BusinessCases = section)}
        >
          <BusinessCases />
        </section>
        <section id="Download" ref={section => (this.Download = section)}>
          <Download
            isMobile={this.state.isMobile}
            scrollToSection={this.scrollToSection}
            showFormContact={this.props.showFormContact}
          />
        </section>
        <section id="Resources" ref={section => (this.Resources = section)}>
          <Resources
            showFormContact={this.props.showFormContact}
            isMobile={this.state.isMobile}
          />
        </section>
        <Footer
          scrollToSection={this.scrollToSection}
          isMobile={this.state.isMobile}
          headerTop={this.state.headerTop}
        />
        <Contact
          showContact={this.props.showContact}
          showFormContact={this.props.showFormContact}
        />
      </div>
    );
  }
}

export default withTranslation("translations")(withRouter(LandingPage));
