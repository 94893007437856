import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { node4, resources, fondoResources } from "../../images";

class Resources extends Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <div
          className="container-resources"
          style={{
            backgroundImage: `url("${fondoResources}")`
          }}
        >
          <div className="container-customers-section">
            <div className="container-texts-customer-section">
              <img src={node4} alt="node-4" />
              <h2
                style={{ color: "white" }}
                className="title-section-customers title-section-clients"
              >
                {t("Resources.title", { framework: "react-i18next" })}
              </h2>
              <span className="subtitle-section-customers subtitle-section-resources">
                <a
                  href="https://gitlab.com/MyTrust/developer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Resources.linkResources", { framework: "react-i18next" })}
                </a>
              </span>
            </div>
            <img
              className="img-section-resources"
              alt="resources"
              src={resources}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(Resources);
