import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { appSection, phone, downloadBtnIcons, appSectionMobile } from '../../images';
import DownloadButton from './DownloadButton';

class Download extends Component {
  render() {
    const { t } = this.props;

    const walletContent = (
      <Fragment>
        <div className="wallet-introduction-container">
          <div className="wallet-info-container">
            <div className="wallet-info-content">
              <p className="wallet-title-app">{t("download.title", { framework: "react-i18next" })}</p>
              <p className="wallet-description-app">{t("download.description", { framework: "react-i18next" })}</p>
              <ol className="wallet-steps-list">
                {t("download.steps", {
                  returnObjects: true
                }).map(step => (
                  <li>{step}</li>
                ))}
              </ol>
            </div>
          </div>
          <div className="wallet-instructions-container">
            <div className="instructions-content">
              <p>{t(`download.links.link.title`, { framework: "react-i18next" })}</p>
              <br></br>{t(`download.links.link.content.1`, { framework: "react-i18next" })}
              <br></br>{t(`download.links.link.content.2`, { framework: "react-i18next" })}
              <br></br>{t(`download.links.link.content.3`, { framework: "react-i18next" })}
              <p>{t(`download.links.link.content.5`, { framework: "react-i18next" })}</p>
              <p>{t(`download.links.link.content.6`, { framework: "react-i18next" })}
                <br></br>
                {t(`download.links.link.content.7`, { framework: "react-i18next" })}
                 <a target="_blank" rel="noopener noreferrer" href="https://onboarding.demo.kaytrust.id/acme">"https://onboarding.demo.kaytrust.id/acme"</a>
                {t(`download.links.link.content.8`, { framework: "react-i18next" })}
              </p>
            </div>
            <div className="download-section-container">
              <div className="phone-image-container">
                <img src={phone} alt="phone" className="phone-img" />
              </div>
              <p className="download-section-title">{t("download.downloadTitle", { framework: "react-i18next" })}</p>
              {t("download.downloadBtns", {
                returnObjects: true
              }).map(btn => (
                <DownloadButton
                  icon={downloadBtnIcons[btn.icon]}
                  subtitle={btn.subtitle}
                  title={btn.title}
                  link={btn.link}
                />
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    );

    return (
      <Fragment>
        <div
          style={{
            background: `url("${appSection}")`,
          }}
          className="container-wallet container-desktop">
          {walletContent}
        </div>
        <div
          style={{
            background: `url("${appSectionMobile}")`
          }}
          className="container-wallet container-mobile">
          {walletContent}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("translations")(Download);
