import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Card } from "antd";
import Slider from "react-slick";
import { node2, businessCasesBenefits } from '../../images';

const { Meta } = Card;

const settings = {
  className: "slider variable-width",
  dots: true,
  infinite: true,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true
};

class BusinessCases extends Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="container-business-case-desktop">
          <div className="container-cards-business">
            <div className="container-card-business-case">  
              <img
                alt="node-2"
                style={{ display: "block", marginBottom: "2em" }}
                src={node2}                           
              />
              <h2 className="title-section-desktop">
                {t("businessCases.titleSection", {
                  framework: "react-i18next"
                })}
              </h2>
              <p className="description-section-dektop">
                {t("businessCases.description", { framework: "react-i18next" })}
              </p>
            </div>
            {t("businessCases.benefits", {
              returnObjects: true
            }).map(businessCase => (
              <div
                key={businessCase.imgName}
                className="container-card-business-case"
              >
                <div className="card-business-case">
                  <div className="card-business-front">
                    <div
                      style={{
                        background: `linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.45) ),url("${
                          businessCasesBenefits[businessCase.imgName]
                        }")`
                      }}
                    >
                      {businessCase.title}
                    </div>
                  </div>
                  <div className="card-business-front card-business-back">
                    <h5>{businessCase.title}</h5>
                    <p>{businessCase.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container-business-case-mobile">
          <img
            className="icon-section-mobile"
            alt="node-2"
            src={node2}
          />
          <span className="title-section-mobile center">
            {t("businessCases.titleSection", { framework: "react-i18next" })}
          </span>
          <div className="carousel-business-case-mobile">
            <Slider {...settings}>
              {t("businessCases.benefits", {
                returnObjects: true
              }).map((businessCase, index) => (
                <div key={index} className="container-card-carousel">
                  <Card
                    hoverable
                    bordered
                    style={{ width: 230 }}
                    cover={
                      <img
                        alt={businessCase.imgName}
                        className="img-carousel-business-case"
                        src={businessCasesBenefits[businessCase.imgName]}
                      />
                    }
                  >
                    <Meta
                      title={businessCase.title}
                      description={businessCase.description}
                    />
                  </Card>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(BusinessCases);
