import React, { Component } from "react";
import { Collapse, Icon } from "antd";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Scroller, scrollInitalState } from "react-skroll";
import Organization from "./organization/Organization";
import Customers from "./customers/Customers";
import Reasons from "./reasons/Reasons";
import { node1 } from "../../images/index";

const { Panel } = Collapse;
let benefitsTitle = ["1"];

class Benefits extends Component {
  state = {
    keyActivePanelMobile: 1,
    initializeScrollWithRoute: false,
    initializeScrollWithRouteMobile: false,
    scroll: scrollInitalState
  };

  scrollWithSectionRouteNameMobile = () => {
    const routeSectionName = this.props.routeSectionName;

    if (
      this.props.langOfRoute === "es" &&
      routeSectionName.match("beneficios/")
    ) {
      this.scrollingIsMobile(routeSectionName.substr(11));
    } else {
      routeSectionName.match("benefits/") &&
        this.scrollingIsMobile(routeSectionName.substr(9));
    }
  };

  scrollingIsMobile = sectionName => {
    const indexBenefitsTitle = Object.keys(benefitsTitle).find(
      object => benefitsTitle[object].routeSectionName === sectionName
    );
    const indexActivePanel = parseInt(indexBenefitsTitle) + 1;

    this.setState({ initializeScrollWithRouteMobile: true }, () => {
      this.props.scrollToSection("Benefits");
      this.changeActiveKey([`${indexActivePanel}`]);
    });
  };

  scrollWithSectionRouteName = () => {
    const routeSectionName = this.props.routeSectionName;

    if (
      this.props.langOfRoute === "es" &&
      routeSectionName.match("beneficios/")
    ) {
      this.scrolling(routeSectionName.substr(11));
    } else {
      routeSectionName.match("benefits/") &&
        this.scrolling(routeSectionName.substr(9));
    }
  };

  scrolling = sectionName => {
    const indexBenefitsTitle = Object.keys(benefitsTitle).find(
      object => benefitsTitle[object].routeSectionName === sectionName
    );

    this.setState({ initializeScrollWithRoute: true }, () => {
      this.props.scrollToSection("Benefits");
      this.scroll.scrollToPosition(
        this.state.scroll.children[indexBenefitsTitle].start
      );
    });
  };

  changeActiveKey = key => {
    this.setState({ keyActivePanelMobile: key });
  };

  render() {
    const { t, i18n } = this.props;

    benefitsTitle = [
      {
        index: 1,
        component: <Organization/>,
        routeSectionName: `${t("Benefits.organization.routeName", {
          framework: "react-i18next"
        })}`,
        title: `${t("Benefits.organization.titleSection", {
          framework: "react-i18next"
        })}`
      },
      {
        index: 2,
        component: <Customers isMobile={this.props.isMobile}/>,
        routeSectionName: `${t("Benefits.customers.routeName", {
          framework: "react-i18next"
        })}`,
        title: `${t("Benefits.customers.titleSection", {
          framework: "react-i18next"
        })}`
      },
      {
        index: 3,
        component: <Reasons />,
        routeSectionName: `${t("Benefits.specificCases.routeName", {
          framework: "react-i18next"
        })}`,
        title: `${t("Benefits.specificCases.titleSection", {
          framework: "react-i18next"
        })}`
      }
    ];

    return (
      <div>
        <div className="container-benefits-section">
          <div className="menu-benefits">
            <img className="icon-section" alt="node-1" src={node1} />
            <div className="texts-menu-benefits">
              {this.state.scroll.children.map((child, i) => (
                <span
                  style={{ cursor: "pointer" }}
                  key={i}
                  className={child.active ? "active" : "inactive"}
                  onClick={event => {
                    event.preventDefault();
                    this.scroll.scrollToPosition(child.start);
                    if (i18n.language === "es") {
                      this.props.history.push(
                        `/es/beneficios/${benefitsTitle[i].routeSectionName}`
                      );
                    } else {
                      this.props.history.push(
                        `/benefits/${benefitsTitle[i].routeSectionName}`
                      );
                    }
                  }}
                >
                  {benefitsTitle[i].title}
                </span>
              ))}
            </div>
          </div>
          <div className="container-benefits-desktop">
            <Scroller
              scrollRef={ref => (this.scroll = ref)}
              autoScroll={true}
              autoFrame={true}
              onScrollChange={scroll => this.setState({ scroll })}
            >
              {benefitsTitle.map(benefit => (
                <section key={benefit.index} name={benefit.title}>
                  {benefit.component}
                </section>
              ))}
            </Scroller>
            {this.state.scroll.children.length > 0 &&
              !!this.props.routeSectionName &&
              !this.state.initializeScrollWithRoute &&
              this.scrollWithSectionRouteName()}
          </div>
        </div>
        <div className="collapse-carousel-benefits">
          <img className="icon-section-mobile" src={node1} alt="node-1" />
          <span className="title-section-mobile">
            {t("menu.Benefits", { framework: "react-i18next" })}
          </span>
          <Collapse
            bordered={false}
            activeKey={this.state.keyActivePanelMobile}
            onChange={this.changeActiveKey}
            expandIcon={({ isActive }) =>
              isActive ? (
                <Icon type="minus-circle" />
              ) : (
                <Icon type="plus-circle" />
              )
            }
          >
            {benefitsTitle.map(benefit => (
              <Panel
                header={benefit.title}
                key={benefit.index}
                style={{ border: 0 }}
              >
                {benefit.component}
              </Panel>
            ))}
          </Collapse>
          {this.props.isMobile &&
            !this.state.initializeScrollWithRouteMobile &&
            !!this.props.routeSectionName &&
            this.scrollWithSectionRouteNameMobile()}
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(withRouter(Benefits));
