import React, { Component } from "react";
import { Redirect } from "react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Page404 from "./components/page404/Page404";
import PrivacyPolicy from "./components/privacy/privacy";
import TermsConditions from "./components/termsConditions/termsConditions";

const sectionRoutesEn = [
  "benefits",
  "business-cases",
  "customers",
  "resources",
  "contact",
  "download"
];

const sectionRoutesEs = [
  "beneficios",
  "casos-negocio",
  "clientes",
  "recursos",
  "contacto",
  "download"
];

const benefitsSectionsEn = [
  "benefits/organization",
  "benefits/customers",
  "benefits/cases"
];
const benefitsSectionsEs = [
  "beneficios/organizacion",
  "beneficios/clientes",
  "beneficios/casos"
];

export default class App extends Component {
  state = {
    showContact: false
  };

  showFormContact = e => {
    this.setState({
      showContact: !this.state.showContact
    });
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <LandingPage
                {...this.props}
                showContact={this.state.showContact}
                showFormContact={this.showFormContact}
                langOfRoute={"en"}
                routeSectionName={""}
              />
            )}
          />
          <Route
            exact
            path="/es"
            render={() => (
              <LandingPage
                {...this.props}
                showContact={this.state.showContact}
                showFormContact={this.showFormContact}
                langOfRoute={"es"}
                routeSectionName={""}
              />
            )}
          />
          <Route exact path="/404" render={() => <Page404 />} />
          <Route
            exact
            path="/contact"
            render={() => (
              <LandingPage
                {...this.props}
                showContact={this.state.showContact}
                showFormContact={this.showFormContact}
                routeSectionName={"/contact"}
                langOfRoute={"en"}
              />
            )}
          />
          <Route
            exact
            path="/es/contacto"
            render={() => (
              <LandingPage
                {...this.props}
                showContact={this.state.showContact}
                showFormContact={this.showFormContact}
                routeSectionName={"/es/contacto"}
                langOfRoute={"es"}
              />
            )}
          />
          <Route
            exact
            path="/es/politica-de-privacidad"
            render={() => <PrivacyPolicy />} 
          />
          <Route
            exact
            path="/privacy-policy"
            render={() => <PrivacyPolicy />} 
          />
          <Route
            exact
            path="/es/terminos-y-condiciones"
            render={() => <TermsConditions />} 
          />
          <Route
            exact
            path="/terms-conditions"
            render={() => <TermsConditions />} 
          />
          {sectionRoutesEn.map((routeSectionName, index) => (
            <Route
              exact
              path={`/${routeSectionName}`}
              render={() => (
                <LandingPage
                  {...this.props}
                  showContact={this.state.showContact}
                  showFormContact={this.showFormContact}
                  routeSectionName={routeSectionName}
                  langOfRoute={"en"}
                />
              )}
            />
          ))}
          {sectionRoutesEs.map((routeSectionName, index) => (
            <Route
              exact
              path={`/es/${routeSectionName}`}
              render={() => (
                <LandingPage
                  {...this.props}
                  showContact={this.state.showContact}
                  showFormContact={this.showFormContact}
                  routeSectionName={routeSectionName}
                  langOfRoute={"es"}
                />
              )}
            />
          ))}
          {benefitsSectionsEn.map((routeSectionName, index) => (
            <Route
              exact
              path={`/${routeSectionName}`}
              render={() => (
                <LandingPage
                  {...this.props}
                  showContact={this.state.showContact}
                  showFormContact={this.showFormContact}
                  routeSectionName={routeSectionName}
                  langOfRoute={"en"}
                />
              )}
            />
          ))}
          {benefitsSectionsEs.map((routeSectionName, index) => (
            <Route
              exact
              path={`/es/${routeSectionName}`}
              render={() => (
                <LandingPage
                  {...this.props}
                  showContact={this.state.showContact}
                  showFormContact={this.showFormContact}
                  routeSectionName={routeSectionName}
                  langOfRoute={"es"}
                />
              )}
            />
          ))}
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    );
  }
}
