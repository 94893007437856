import React from "react";
import ReactDOM from "react-dom";
import common_es from "./translation/es/common";
import common_en from "./translation/en/common";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import App from "./App";
import "./styles/index.css";


i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      translations: common_en
    },
    es: {
      translations: common_es
    }
  },
  defaultNS: "translations",
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);
