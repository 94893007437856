import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Icon } from "antd";
import { logoColor, logoNttdata } from "../../images";

class Footer extends Component {

  scrollAndChangeRoute = menuItem => {
    this.props.scrollToSection(menuItem);

    const sectionRouteName = this.props.t(`sectionRoutes.${menuItem}`, {
      framework: "react-i18next"
    });

    this.props.i18n.language === "es"
      ? this.props.history.push(`/es/${sectionRouteName}`)
      : this.props.history.push(`/${sectionRouteName}`);
  };

  render() {
    const { t, i18n } = this.props;
    const menuItems = ["Benefits", "BusinessCases", "Customers", "Resources", "PrivacyPolicy","TermsConditions"];

    return (
      <div
        className="footer-section"
        style={{
          marginBottom: `${
            this.props.isMobile & !this.props.headerTop ? "9vh" : "0"
          }`
        }}
      >
        <div>
          <div className="powered-footer">
            <img
              onClick={() => {
                this.props.scrollToSection("Mytrust");
                this.props.history.push(
                  `${i18n.language === "es" ? "/es" : "/"}`
                );
              }}
              alt="logo-everis"
              className="logo-mytrust"
              src={logoColor}
            />
          </div>
          <div className="menu-footer">
            <div className="text-menu-footer">
              {menuItems.map((menuItem, index) => (
                <span
                  key={index}
                  className="menu-footer-item"
                  onClick={() => this.scrollAndChangeRoute(menuItem)}
                >
                  {t(`menu.${menuItem}`, { framework: "react-i18next" })}
                </span>
              ))}
            </div>
          </div>
          <div className="follow-footer">
            <span className="title-footer">
              {t("footer.follow", { framework: "react-i18next" })}
            </span>
            <div className="follow-icons">
              {t("footer.iconsFollow", {
                returnObjects: true
              }).map((icon, index) => (
                <a
                  key={index}
                  href={icon.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon type={icon.iconName} />
                </a>
              ))}
            </div>
          </div>
          <a href="https://www.nttdata.com/global/en/" rel="noopener noreferrer" target="_blank" className="powered-footer">
            <img alt="logo-nttdata" className="logo-everis" src={logoNttdata} />
          </a>
        </div>
        <div className="copyright-section">
          <span>{t("footer.copyright", { framework: "react-i18next" })}</span>
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(withRouter(Footer));
