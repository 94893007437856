import banking from "./images/banking.jpg";
import education from "./images/education.png";
import electronicCommerce from "./images/electronic-commerce.png";
import government from "./images/government.png";
import health from "./images/health.png";
import iot from "./images/iot.png";
import telecommunications from "./images/telecommunications.png";

import coins from "./icons/coins.svg";
import identidad from "./icons/identidad.svg";
import personas from "./icons/personas.svg";
import seguridad from "./icons/seguridad.svg";

import safetyPurple from "./icons/safety-purple.svg";
import safetyOrange from "./icons/safety-orange.svg";
import safety from "./icons/safety.svg";
import cloudUpload from "./icons/cloud-upload.svg";
import cloudUploadPurple from "./icons/cloud-upload-purple.svg";
import cloudUploadOrange from "./icons/cloud-upload-orange.svg";
import settings from "./icons/settings.svg";
import settingsPurple from "./icons/settings-purple.svg";
import settingsOrange from "./icons/settings-orange.svg";
import lock from "./icons/lock.svg";
import lockPurple from "./icons/lock-purple.svg";
import lockOrange from "./icons/lock-orange.svg";
import handshakePurple from "./icons/handshake-purple.svg";
import handshakeOrange from "./icons/handshake-orange.svg";
import handshake from "./icons/handshake.svg";
import key from "./icons/key.svg";
import keyPurple from "./icons/key-purple.svg";
import keyOrange from "./icons/key-orange.svg";
import boltPurple from "./icons/bolt-purple.svg";
import boltOrange from "./icons/bolt-orange.svg";
import bolt from "./icons/bolt.svg";

import hacker from "./icons/hacker.svg";
import mobile from "./icons/mobile.svg";
import creditCard from "./icons/credit-card.svg";

import alastriaOrg from "./images/alastria-org.png";
import bidOrg from "./images/bid-org.png";
import cclOrg from "./images/ccl-org.png";
import everisOrg from "./images/everis-org.png";
import nttdataOrg from "./images/nttdata-org.png";
import lacchainOrg from "./images/lacchain-org.png";
import legalblockOrg from "./images/legalblock-org.png";

import playstore from "./images/playstore.svg";
import appstore from "./images/appstore.svg";

export { default as banking } from "./images/banking.jpg";
export { default as clientsMobile } from "./images/clients-mobile.png";
export { default as clients } from "./images/clients.png";
export { default as customers } from "./images/customers.png";
export { default as education } from "./images/education.png";
export { default as electronicCommerce } from "./images/electronic-commerce.png";
export { default as fondoMytrust } from "./images/fondo-mytrust.jpeg";
export { default as government } from "./images/government.png";
export { default as health } from "./images/health.png";
export { default as iot } from "./images/iot.png";
export { default as logoColor } from "./images/logo-color.png";
export { default as logoNttdata } from "./images/logo-ntt-data-dark.svg";
export { default as logoGrey } from "./images/logo-grey.svg";
export { default as logoWhite } from "./images/logo-white.png";
export { default as resources } from "./images/resources.png";
export { default as verified } from "./images/verified.svg";
export { default as node1 } from "./icons/node-1.svg";
export { default as node2 } from "./icons/node-2.svg";
export { default as node3 } from "./icons/node-3.svg";
export { default as node4 } from "./icons/node-4.svg";
export { default as left } from "./icons/left.svg";
export { default as right } from "./icons/right.svg";
export { default as error404 } from "./images/404.png";
export { default as fondoResources } from "./images/fondo-resources.png";
export { default as iconDown } from "./icons/iconDown.svg";
export { default as showroom } from "./images/showroom.png";
export { default as showroomMobile } from "./images/showroom-mobile.png";
export { default as appSection } from "./images/app-section.png";
export { default as appSectionMobile } from "./images/app-section-mobile.png";
export { default as phone } from "./images/phone.png";

export const downloadBtnIcons = {
   playstore,
   appstore
}


export const businessCasesBenefits = {
   banking,
   education,
   telecommunications,
   electronicCommerce,
   health,
   iot,
   government
}

export const benefitOrganization = {
   coins,
   identidad,
   personas,
   seguridad
}

export const benefitSpecificCases = {
   hacker,
   mobile,
   creditCard
}

export const benefitCustomersImages = {
    safetyPurple,
    safetyOrange,
    safety,
    cloudUpload,
    cloudUploadPurple,
    cloudUploadOrange,
    settings,
    settingsPurple,
    settingsOrange,
    lock,
    lockPurple,
    lockOrange,
    handshake,
    handshakePurple,
    handshakeOrange,
    key,
    keyPurple,
    keyOrange,
    bolt,
    boltPurple,
    boltOrange
}

export const organizations = {
    alastriaOrg,
    bidOrg,
    cclOrg,
    everisOrg,
    nttdataOrg,
    lacchainOrg,
    legalblockOrg
}



