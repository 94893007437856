import React, { Component } from "react";
import { Card } from "antd";

const { Meta } = Card;

export default class CardOrganization extends Component {
  render() {
    return (
      <Card>
        <Meta
          avatar={
            <img
              src={this.props.icon}
              alt={this.props.iconName}
            />
          }
          title={this.props.title}
          description={this.props.description}
        />
      </Card>
    );
  }
}
