import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Layout, Menu, Button, Icon, Dropdown, Drawer } from "antd";
import { logoColor, logoWhite } from "../../images";

const { Header } = Layout;

class Navbar extends Component {
  state = { isVisibleDrawer: false };

  scrollAndChangeRoute = menuItem => {
    this.props.scrollToSection(menuItem);
    const sectionRouteName = this.props.t(`sectionRoutes.${menuItem}`, {
      framework: "react-i18next"
    });

    this.props.i18n.language === "es"
      ? this.props.history.push(`/es/${sectionRouteName}`)
      : this.props.history.push(`/${sectionRouteName}`);
  };

  backgroundHeader = () => {
    if (this.props.headerTop) return "transparent";
    else return this.props.isMobile ? "#643ee9" : "white";
  };

  boxShadowHeader = () => {
    if (this.props.headerTop) return "none";
    else
      return this.props.isMobile ? "none" : "0 2px 4px 0 rgba(0, 0, 0, 0.09)";
  };

  getLogo = () => {
    if (this.props.headerTop) return logoWhite;
    else return this.props.isMobile ? logoWhite : logoColor;
  };

  isHeaderBottom = () => {
    if (this.props.headerTop) return "auto";
    else return this.props.isMobile ? "0px" : "auto";
  };

  colorItemsHeader = () => {
    if (this.props.headerTop) return "white";
    else return this.props.isMobile ? "white" : "#8a8f94";
  }

  showDrawer = e => {
    this.setState({
      isVisibleDrawer: !this.state.isVisibleDrawer
    });
  };

  render() {
    const { t, i18n } = this.props;
    const menuItems = ["Benefits", "BusinessCases", "Download", "Customers", "Resources"];

    const menuLanguages = (
      <Menu>
        <Menu.Item>
          <span
            onClick={() => {
              this.props.history.push("/");
              i18n.changeLanguage("en");
              this.props.isMobile && this.showDrawer();
            }}
          >
            {t("languages.en", { framework: "react-i18next" })}
          </span>
        </Menu.Item>
        <Menu.Item>
          <span
            onClick={() => {
              this.props.history.push("/es");
              i18n.changeLanguage("es");
              this.props.isMobile && this.showDrawer();
            }}
          >
            {t("languages.es", { framework: "react-i18next" })}
          </span>
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Header
          style={{
            minHeight: `${
              this.props.isMobile & !this.props.headerTop ? "9vh" : "11vh"
              }`,
            bottom: `${this.isHeaderBottom()}`,
            boxShadow: `${this.boxShadowHeader()}`,
            background: `${this.backgroundHeader()}`
          }}
        >
          <img
            width={200}
            alt={t("head.title", { framework: "react-i18next" })}
            style={{
              cursor: "pointer",
              height: `${
                this.props.isMobile & !this.props.headerTop ? "60%" : "auto"
                }`
            }}
            onClick={() => {
              this.props.scrollToSection("Mytrust");
              this.props.history.push(
                `${i18n.language === "es" ? "/es" : "/"}`
              );
            }}
            src={this.getLogo()}
          />
          <Menu
            style={{ color: this.colorItemsHeader() }}
            className={`menu-navbar-desktop ${
              this.props.headerTop ? "menu-white" : "menu-gray"
              }`}
            mode="horizontal"
          >
            {menuItems.map((menuItem, index) => (
              <Menu.Item
                key={index}
                onClick={() => this.scrollAndChangeRoute(menuItem)}
              >
                {t(`menu.${menuItem}`, { framework: "react-i18next" })}
              </Menu.Item>
            ))}
          </Menu>
          <div className="container-dropdown">
            <Button
              style={{
                marginRight: "1.5em",
                backgroundColor: `${
                  this.props.headerTop ? "transparent" : "#643ee9"
                  }`
              }}
              onClick={() => {
                this.props.showFormContact();
                this.props.history.push(
                  i18n.language === "es" ? "/es/contacto" : "/contact"
                );
              }}
              type="primary"
            >
              {t("menu.ContactUs", { framework: "react-i18next" })}
            </Button>
            <Dropdown overlay={menuLanguages}>
              <span
                className="ant-dropdown-link"
                style={{
                  color: `${this.props.headerTop ? "white" : "#8a8f94"}`
                }}
              >
                <Icon type="global" style={{ marginRight: "0.5em" }} />
                <span className="text-dropdown">
                  {t(`languages.${i18n.language}`, {
                    framework: "react-i18next"
                  })}
                </span>
                <Icon type="down" />
              </span>
            </Dropdown>
          </div>
          <Icon
            style={{
              color: `${
                !this.props.isMobile & !this.props.headerTop ? "#6f72ea" : "white"
                }`
            }}
            onClick={this.showDrawer}
            className="menu-icon-header"
            type="menu"
          />
        </Header>
        <Drawer
          className={`${
            this.state.isVisibleDrawer ? "drawer-visible" : "drawer-invisible"
            }`}
          placement={`${this.props.headerTop ? "top" : "bottom"}`}
          closable={true}
          onClose={this.showDrawer}
          visible={this.state.isVisibleDrawer}
        >
          <img
            width={150}
            style={{ cursor: "pointer" }}
            alt={"hola" + t("head.title", { framework: "react-i18next" })}
            onClick={() => {
              this.props.scrollToSection("Mytrust");
              this.props.history.push(
                `${i18n.language === "es" ? "/es" : "/"}`
              );
            }}
            src={logoWhite}
          />
          <Menu
            style={{ color: "white" }}
            className={"menu-navbar-mobile menu-white"}
            mode="vertical"
          >
            <Menu.Item
              onClick={() => {
                this.props.scrollToSection("Mytrust");
                this.showDrawer();
              }}
            >
              {t("menu.Home", { framework: "react-i18next" })}
            </Menu.Item>
            <Menu.Item>
              <Dropdown overlay={menuLanguages}>
                <span className="ant-dropdown-link" style={{ color: "white" }}>
                  <Icon type="global" style={{ marginRight: "0.5em" }} />
                  {t(`languages.${i18n.language}`, {
                    framework: "react-i18next"
                  })}
                  <Icon style={{ margin: "0 0 0 10px" }} type="down" />
                </span>
              </Dropdown>
            </Menu.Item>
            {menuItems.map((menuItem, index) => (
              <Menu.Item
                key={index}
                onClick={() => {
                  this.scrollAndChangeRoute(menuItem);
                  this.showDrawer();
                }}
              >
                {t(`menu.${menuItem}`, { framework: "react-i18next" })}
              </Menu.Item>
            ))}
            <Menu.Item
              style={{
                marginTop: "2em"
              }}
            >
              <Button
                style={{
                  backgroundColor: `${
                    this.props.headerTop ? "transparent" : "#643ee9"
                    }`
                }}
                onClick={() => {
                  this.props.showFormContact();
                  this.props.history.push(
                    i18n.language === "es" ? "/es/contacto" : "/contact"
                  );
                }}
                type="primary"
              >
                {t("menu.ContactUs", { framework: "react-i18next" })}
              </Button>
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    );
  }
}

export default withTranslation("translations")(withRouter(Navbar));
