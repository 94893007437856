import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class DownloadButton extends Component {
  render() {

    return (
      <a href={this.props.link} target="_blank" rel="noopener noreferrer" className="app-btn">
        <div className="icon-container">
          <img src={this.props.icon} alt="playstore" className="icon-button" />
        </div>
        <div className="text-container">
          <p className="subtitle-btn">{this.props.subtitle}</p>
          <p className="title-btn">{this.props.title}</p>
        </div>
      </a>
    );
  }
}

export default withTranslation("translations")(DownloadButton);
