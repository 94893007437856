import React, { Component } from "react";
import { Carousel } from "antd";
import { withTranslation } from "react-i18next";
import CardOrganization from "../organization/CardOrganization";
import {
  benefitCustomersImages,
  customers,
  left,
  right
} from "../../../images";

class Customer extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.carousel.next();
  }

  previous() {
    this.carousel.prev();
  }

  render() {
    const { t } = this.props;
    
    return (
      
      <div className="container-section-benefit container-benefit-customer">
        <div className="container-benefit-customer-texts">
          <h2 className="title-benefit">
            {t("Benefits.customers.title", { framework: "react-i18next" })}
          </h2>
          <p className="description-benefit">
            {t("Benefits.customers.description", {
              framework: "react-i18next"
            })}
          </p>
        </div>
        <div className="container-cards-customer">
          {t("Benefits.customers.benefits", { returnObjects: true })
          .map((benefit) =>
              <div
                key={benefit.iconName}
                className="container-card-customer"
                onMouseOver={e => {
                  e.currentTarget.firstElementChild.firstElementChild.src =
                    benefitCustomersImages[benefit.iconNameHover];
                }}
                onMouseLeave={e => {
                  e.currentTarget.firstElementChild.firstElementChild.src =
                    benefitCustomersImages[benefit.iconName];
                }}
              >
                <div className="icon-customer">
                  <img
                    src={benefitCustomersImages[benefit.iconName]}
                    alt={benefit.iconName}
                  />
                </div>
                <p>{benefit.description}</p>
              </div>
          )}
          <div className="center-image-customers">
            <img src={customers} alt="customers" />
          </div>
        </div>
        <div className="container-carousel">
          <img
            className="arrowRight"
            src={right}
            alt="right"
            onClick={this.next}
          />
          <Carousel ref={node => (this.carousel = node)} arrows={true}>
            { t("Benefits.customers.benefits", {returnObjects: true })
            .map(benefit =>
                <div key={benefit.iconName}>
                  <CardOrganization
                    iconName={benefit.iconName}
                    icon={benefitCustomersImages[benefit.iconNameMobile]}
                    title={benefit.description}
                  />
                </div>
            )}
          </Carousel>
          <img
            className="arrowLeft"
            src={left}
            alt="left"
            onClick={this.previous}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(Customer);
