import React, { Component } from "react";
import { Carousel } from "antd";
import CardOrganization from "./CardOrganization";
import { benefitOrganization, left, right } from '../../../images';
import { withTranslation } from "react-i18next";

class Organization extends Component {

  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }

  render() {
    const { t } = this.props;

    const cardsOrganization = t("Benefits.organization.benefits", {
      returnObjects: true
    }).map((benefit) => (
      <CardOrganization
        key={benefit.iconName}
        icon={benefitOrganization[benefit.iconName]}
        iconName={benefit.iconName}
        title={benefit.title}
        description={benefit.description}
      />
    ));

    return (
      <div className="container-section-benefit container-benefit-column">
        <h2 className="title-benefit">
          {t("Benefits.organization.title", { framework: "react-i18next" })}
        </h2>
        <p className="description-benefit">
          {t("Benefits.organization.description", {
            framework: "react-i18next"
          })}
        </p>
        <div className="container-cards-organization">
          {cardsOrganization}
        </div>
        <div className="container-carousel">
          <img
            className="arrowRight"
            src={right}
            alt="right"
            onClick={this.next} />
          <Carousel
            ref={node => (this.carousel = node)}
            arrows={true}
          >
            {cardsOrganization}
          </Carousel>
          <img
            className="arrowLeft"
            src={left}
            alt="left"
            onClick={this.previous} />
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(Organization);
