import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button } from "antd";

class TermsConditions extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container-terms-contidions">
        <div className="container-terms-body">
          <p className="terms-contidions-title">
            {t(`termsConditions.titleTerms`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.description`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.1.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.1.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.2.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.2.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.3.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.3.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.4.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.4.content.1`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.4.content.2`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.4.content.3`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.4.content.4`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.4.content.5`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.5.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.5.content.1`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.5.content.2`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.6.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.6.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.7.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.7.content.1`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.7.content.2`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.8.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.8.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.9.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.9.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.10.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.10.content.1`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.10.content.2`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.10.content.3`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.10.content.4`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.11.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.11.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.12.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.12.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.13.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.13.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.14.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.14.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-Contidions-subtitle">
            {t(`termsConditions.details.15.title`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.15.content`, { framework: "react-i18next" })}
          </p>
          <p className="terms-condition-body">
            {t(`termsConditions.details.16.content`, { framework: "react-i18next" })}
          </p>
        </div>
        <Button
          className="button-terms-Contidions"
          onClick={() => this.props.history.push("/")}
        >
          {t("page404.backButton", { framework: "react-i18next" })}
        </Button>
      </div>
    );
  }
}

export default withTranslation("translations")(withRouter(TermsConditions));
