import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Form, Input, Button } from "antd";
import { sendEmail } from '../utils';
import { verified } from '../../images';
import ReCAPTCHA from 'react-google-recaptcha';

const FormItem = Form.Item;
const { TextArea } = Input;

export default withTranslation("translations")(
  Form.create()(
    class Contact extends Component {
      state = {
        isVisibleRequiredMessage: false,
        success: false,
        isSubmit: false
      };
      
      send = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
          try {
            if (!err) {
              this.setState({ isVisibleRequiredMessage: false });
              await sendEmail(values, this.props.t("contact.emailSubject", { framework: "react-i18next" }));
              this.props.form.resetFields();
              this.setState({ success: true });
            } else {
              this.setState({ isVisibleRequiredMessage: true });
            }
          } catch (error) {
            console.trace(error);
          }
        });
      };

      closeModal = () => {
        this.props.showFormContact();
        this.setState({ success: false, isSubmit: false });
      }

      showSubmit() {
        this.setState({ isSubmit: true });
      }

      render() {
        const { t } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
          <Modal
            footer={false}
            visible={this.props.showContact}
            onCancel={this.closeModal}
          >
            {
              this.state.success ? (
                <div className="success-container">
                  <img
                    alt="verified"
                    className="success-image"
                    src={verified}
                  />
                  <p className="success-title">
                    {t("contact.sentMessage", { framework: "react-i18next" })}
                  </p>
                  <p className="success-description">
                    {t("contact.successDescription", { framework: "react-i18next" })}
                  </p>
                  <Button
                    className="success-button"
                    onClick={() => this.closeModal()}
                    type="default"
                  >
                    {t("contact.acceptButton", { framework: "react-i18next" })}
                  </Button>
                </div>
              ) : (
                  <div>
                    <h2 className="t-white title-contact">
                      {t("contact.title", { framework: "react-i18next" })}
                    </h2>
                    <p className="t-white subtitle-contact">
                      {t("contact.subTitle", { framework: "react-i18next" })}
                    </p>
                    <Form onSubmit={this.send} className="login-form">
                      <FormItem>
                        {getFieldDecorator("email", {
                          rules: [{ required: true, message: " " }]
                        })(
                          <Input
                            className="contact-input"
                            type="email"
                            placeholder={t("contact.email", {
                              framework: "react-i18next"
                            })}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator("company", {
                          rules: [{ required: true, message: " " }]
                        })(
                          <Input
                            className="contact-input"
                            placeholder={t("contact.company", {
                              framework: "react-i18next"
                            })}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator("name", {
                          rules: [{ required: true, message: " " }]
                        })(
                          <Input
                            className="contact-input"
                            placeholder={t("contact.fullName", {
                              framework: "react-i18next"
                            })}
                          />
                        )}
                      </FormItem>
                      <Form.Item>
                        {getFieldDecorator("phone", {
                          rules: [{ required: true, message: " " }]
                        })(
                          <Input
                            className="contact-input"
                            type="number"
                            placeholder={t("contact.phone", {
                              framework: "react-i18next"
                            })}
                          />
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator("message", {
                          rules: [{ required: true, message: " " }]
                        })(
                          <TextArea
                            className="contact-input textarea-input"
                            rows={3}
                            placeholder={t("contact.message", {
                              framework: "react-i18next"
                            })}
                          />
                        )}
                      </Form.Item>
                      <h5
                        className="error-contact"
                        style={{
                          display: `${
                            this.state.isVisibleRequiredMessage ? "block" : "none"
                            }`
                        }}
                      >
                        {t("contact.required", { framework: "react-i18next" })}
                      </h5>
                      <ReCAPTCHA
                        sitekey="6Ldge-QUAAAAADajix4HPU8ReQry-bS18dRrTWZI"
                        onChange={this.showSubmit.bind(this)}
                      />
                      {
                        this.state.isSubmit &&
                        <Button htmlType="submit" className="login-form-button">
                          {t("contact.send", { framework: "react-i18next" })}
                        </Button>
                      }
                    </Form>
                  </div>
                )
            }
          </Modal >
        );
      }
    }
  )
);
