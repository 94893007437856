import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  node3,
  organizations
} from "../../images";

class Customers extends Component {
  render() {
    const { t } = this.props;

    const topOrganizations = t("customers.topOrganizations", {
      returnObjects: true
    }).map(org => (
      <a key={Math.random()} href={org.link} target="_blank" rel="noopener noreferrer" className="org-container-image">
        <img
          className="clickable-image-org"
          alt={org.imgName}
          src={organizations[org.imgName]}
        />
      </a>
    ));

    const bottomOrganizations = t("customers.bottomOrganizations", {
      returnObjects: true
    }).map(org => (
      <a key={Math.random()} href={org.link} target="_blank" rel="noopener noreferrer" className="org-container-image">
        <img
          className="clickable-image-org"
          alt={org.imgName}
          src={organizations[org.imgName]}
        />
      </a>
    ));

    return (
      <div>
        <div className="container-customers-section-clients">
          <div className="container-texts-customer-section-org">
            <img src={node3} alt="node-3" />
            <h2 className="title-section-customers">
              {t("customers.title", { framework: "react-i18next" })}
            </h2>
            <span
              className="subtitle-section-customers underline-primary-color"
              onClick={this.props.showFormContact}
            >
              {t("customers.contactUs", { framework: "react-i18next" })}
            </span>
          </div>
          <div className="clients-desktop-img">
            <div className="container-image-organization top-container-image">
              {topOrganizations}
            </div>
            <div className="container-image-organization">
              {bottomOrganizations}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(Customers);
